import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';
import { ApiService } from './../http/api.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  constructor(private apiService: ApiService) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getFinancialCategory() {
    return this.apiService.apiGet('/action/financial-categories/all');
  }

  getProductByFinancialCategory(id) {
    return this.apiService.apiGet('/action/financial-products-by-category/' + id);
  }

}
