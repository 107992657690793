
import { Component, OnInit, AfterViewInit, Output, Input, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FranchisesService } from 'src/app/core/services/franchises.service';
import { PopulationGroupService } from 'src/app/core/services/population-group.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit, AfterViewInit {
  groups: any[];

  @Input() statusFilter: string;
  @Input() filters: string[];

  @Output() emitGroup = new EventEmitter<any>();
  @Output() emitSemester = new EventEmitter<any>();
  @Output() emitStatus = new EventEmitter<any>();

  @ViewChild('containerFilter') containerFilter: ElementRef;

  constructor(
    private renderer: Renderer2,
    private populationGroupService: PopulationGroupService
  ) { }

  ngOnInit() {
    if (this.showFilter('group')) {
      this.getGroups();
    }
  }

  ngAfterViewInit() {
    const height = this.containerFilter.nativeElement.scrollHeight;
    this.renderer.setStyle(this.containerFilter.nativeElement, 'max-height', `${height + 1}px`);
  }

  async getGroups() {
    this.groups = await this.populationGroupService.getPopulationGroup();
  }

  showFilter(el) {
    return this.filters.indexOf(el) !== -1;
  }

}
