import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  /**
   * Limpa a string, tirando acentuação
   * @param stringEl string
   * @return string
   */
  public clearString(stringEl: string): string {
    return stringEl.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * remove do array os objetos que ja existem
   * @param array any[]
   * @param param string
   * @param objName string
   * @return any[]
   */
  public removeElementIfExists(array: any[], param: string, objName: string): any[] {
    array.map((elem, index) => {
      if ( elem[param].indexOf(objName) > -1 ) {
        array.splice(index, 1);
      };
    });
    return array;
  }

  /**
   * Formatação para real (R$..)
   * @param price number
   * @return string
   */
  getFormattedPrice(price: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

}
