import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';
import { ApiService } from './../http/api.service';

@Injectable({
  providedIn: 'root'
})
export class FranchisesService {

  constructor(private apiService: ApiService) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getFranchises(options: string = '') {
    return this.apiService.apiGet(`/franchise${options}`);
  }

  getFranchisesById(id) {
    return this.apiService.apiGet('/franchise/' + id);
  }

  getFranchisesByHalf(halfId:string) {
    return this.apiService.apiGet('/franchise/select-report', 'periodId='+halfId);
  }

  putFranchises(id, obj) {
    return this.apiService.apiPut('/franchise/' + id, obj);
  }

}
