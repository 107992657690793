import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMask]'
})
export class MaskDirective {
  private initialized: boolean = false;
  constructor(private el: ElementRef) { }

  ngOnInit() {
    const initialValue = this.el.nativeElement.value;
    if (initialValue) {
      this.applyMask();
      this.initialized = true;
    }
  }

  applyMask() {
    if(this.initialized)
      return
    let maskedValue = this.mask(this.el.nativeElement.value);
    if (maskedValue !== this.el.nativeElement.value) {
      this.el.nativeElement.value = maskedValue;
    }
  }

  mask(value: string): string {
    if (value) {
      if (value.length === 11) {
          return `(${value.slice(0, 2)}) ${value.slice(2, 3)} ${value.slice(3, 7)}-${value.slice(7)}`;
      } else if (value.length === 10) {
          return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`;
      } else {
          return value;
      }
    } else {
        return value;
    }
}

}
