import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

interface Alert {
  title?: string;
  text?: string;
  icon: string;
  btnConfirm?: string;
  btnCancelar?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  emitAlertConfirm(obj: Alert) {
    return Swal.fire({
      title: obj.title ?? '',
      text: obj.text ?? '',
      icon: obj.icon,
      heightAuto: false,
      showCancelButton: true,
      confirmButtonText: obj.btnConfirm ?? 'Confirmar',
      cancelButtonText: obj.btnCancelar ?? 'Cancelar'
    } as object);
  }

  emitAlert(obj: Alert) {
    return Swal.fire({
      title: obj.title ?? '',
      text: obj.text ?? '',
      icon: obj.icon,
      heightAuto: false
    } as object).then(() =>
      location.reload()
    );
  }
}
