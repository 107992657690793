import { Injectable } from '@angular/core';

import { head, franchise, headAcquisition } from '../mocks/table-mock';


@Injectable({
  providedIn: 'root'
})
export class TablesService {

  constructor() { }

  head: any[] = head;
  franchise: any[] = franchise;
  headAcquisition: any[] = headAcquisition;

  getHeader() {
    return this.head;
  }

  getHeaderPerSemester(semester) {
    return this.head.find(arg => arg.referenceCode === semester);
  }

  getHeaderAcquisitionPerSemester(semester) {
    return this.headAcquisition.find(arg => arg.referenceCode === semester);
  }

  getFranchise() {
    return this.franchise;
  }

}
