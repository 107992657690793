import { Component, OnInit, AfterViewInit, HostListener, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  userRole: boolean;
  listLi: any[] = [];
  listRemoved: any[] = [];
  listWidthLi: any[] = [];
  listWidthRemoved: any[] = [];
  widthMax: number;
  widthActual: number;
  widthPlus: number;
  resize: boolean = false;
  loadNavbar: boolean = false;

  @ViewChildren('itemNavbar') itemNavbar;

  @ViewChild('listNavbar') listNavbar;

  @ViewChild('plusMenu') plusMenu;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.resize) {
      this.resize = true;


      this.widthActual = this.listNavbar.nativeElement.offsetWidth;
      this.widthPlus = this.listRemoved.length > 0 ? this.plusMenu.nativeElement.offsetWidth : 0;

      while (this.widthActual - 150 <= this.widthMax + this.widthPlus) {
        this.listRemoved.push(this.listLi[this.listLi.length - 1]);
        this.listLi.splice(-1, 1);

        this.listWidthRemoved.push(this.listWidthLi[this.listWidthLi.length - 1]);
        this.listWidthLi.splice(-1, 1);

        this.setWidthMax();
      }

      while (
        this.listWidthRemoved.length > 0 &&
        this.widthActual - 40 >
        (this.widthMax + this.listWidthRemoved[this.listWidthRemoved.length - 1] + this.widthPlus)
      ) {
        this.listLi.push(this.listRemoved[this.listRemoved.length - 1]);
        this.listRemoved.splice(-1, 1);

        this.listWidthLi.push(this.listWidthRemoved[this.listWidthRemoved.length - 1]);
        this.listWidthRemoved.splice(-1, 1);

        this.setWidthMax();
      }

      this.setWidthMax();
      this.resize = false;

    }
  }

  constructor(
    public router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.widthActual = this.listNavbar.nativeElement.offsetWidth;
    this.widthPlus = this.listRemoved.length > 0 ? this.plusMenu.nativeElement.offsetWidth : 0;

    this.itemNavbar.changes.subscribe(() => {
      setTimeout(() => {
        if (!this.loadNavbar) {
          this.itemNavbar.toArray().forEach(el => {
            this.listLi.push(el.nativeElement);
            this.listWidthLi.push(el.nativeElement.offsetWidth);
          });
          this.setWidthMax();
          this.initList();
          this.loadNavbar = true;
        }
      }, 0);
    });

    setTimeout(_ => {
      this.getUserRoleState();
    }, 250);
  }

  getUserRoleState() {
    this.userRole = this.authService.appUserRoleState();
  }

  initList() {
    while (this.widthActual - 100 <= this.widthMax + this.widthPlus) {
      this.listRemoved.push(this.listLi[this.listLi.length - 1]);
      this.listLi.splice(-1, 1);

      this.listWidthRemoved.push(this.listWidthLi[this.listWidthLi.length - 1]);
      this.listWidthLi.splice(-1, 1);

      this.setWidthMax();
    }

    while (
      this.listWidthRemoved.length > 0 &&
      this.widthActual - 100 >
      (this.widthMax + this.listWidthRemoved[this.listWidthRemoved.length - 1] + this.widthPlus)
    ) {
      this.listLi.push(this.listRemoved[this.listRemoved.length - 1]);
      this.listRemoved.splice(-1, 1);

      this.listWidthLi.push(this.listWidthRemoved[this.listWidthRemoved.length - 1]);
      this.listWidthRemoved.splice(-1, 1);

      this.setWidthMax();
    }
  }

  setWidthMax() {
    this.widthMax = 0;
    this.listWidthLi.forEach((result, i) => {
      this.widthMax += result;
      if (this.listWidthLi.length === i + 1) {
        this.resize = false;
      }
    });
  }

  checkNavbar(el) {
    if (this.listLi.length > 0) {
      let hasItem = false;
      this.listLi.filter(result => {
        if (result.id.indexOf(el) !== -1) {
          hasItem = true;
        }
      });
      return hasItem;
    }
    return true;
  }

}
