import { Injectable, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt/src/jwthelper.service';

import { ApiService } from '../http/api.service';

import { environment } from 'src/environments/environment';

const helper = new JwtHelperService();

@Injectable()
export class AuthService {
  public userToken: any;
  public userAuthenticated = false;

  userAuthenticatedEmitter = new EventEmitter<boolean>();

  constructor(private apiService: ApiService,
              private router: Router) {
    this.apiService = apiService.setAPI(environment.BACKEND_URL);
    this.userToken = localStorage.getItem('userToken');
  }

  checkUserLogin(data) {
    return this.apiService.login('/auth', data)
      .pipe(map(user => {
        if (user) {
          this.userAuthenticated = true;
          this.userAuthenticatedEmitter.emit(true);
          localStorage.setItem('userToken', JSON.stringify(user));
          this.userToken = user;
        } else {
          this.userAuthenticated = false;
          this.userAuthenticatedEmitter.emit(false);
        }
        return user;
      }));
  }

  getFranchiseName() {
    const token = localStorage.getItem('userToken');
    const userObj = helper.decodeToken(token);

    return userObj.franchise;
  }

  getFrachiseFinancialId() {
    const token = localStorage.getItem('userToken');
    const userObj = helper.decodeToken(token);

    return userObj.financialIdentify;
  }

  getRole(){
    if (this.isAuthenticated()) {
      const token = localStorage.getItem('userToken');
      const userObj = helper.decodeToken(token);

      return userObj.role;
    }
  }

  appUserRoleState() {
    if (this.isAuthenticated()) {
      const token = localStorage.getItem('userToken');
      const userObj = helper.decodeToken(token);

      if (userObj.role === 'FRANCHISOR_MARKETING' || userObj.role === 'FRANCHISOR_ADMIN') {
        return true;
      }

      if ((userObj.role === 'FRANCHISE_ADMIN' || userObj.role === 'FRANCHISE_OPERATOR')) {
        return false;
      }

      this.logout();

    }
  }

  isAuthenticated() {
  // EXISTÊNCIA DE TOKEN
    if (this.userToken) {
      this.userAuthenticated = true;
      this.userAuthenticatedEmitter.emit(true);
    } else {
      this.userAuthenticated = false;
      this.userAuthenticatedEmitter.emit(false);
    }

    return this.userAuthenticated;
  }

  logout() {
    this.userToken = null;
    this.userAuthenticated = false;
    this.userAuthenticatedEmitter.emit(false);
    localStorage.removeItem('userToken');
    this.router.navigate(['/login']);
  }

  getUserToken() {
    return localStorage.getItem('userToken');
  }

}
