import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  getNextFourYears() {
    let startYear = new Date().getFullYear();
    const endYear = startYear + 3;
    const years = [];
    while (startYear <= endYear) {
      years.push(startYear);
      startYear++;
    }
    return years;
  }

}
