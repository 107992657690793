import { Injectable } from '@angular/core';

import { ApiService } from './../http/api.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor(private apiService: ApiService) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getAction() {
    return this.apiService.apiGet('/action');
  }

  postAction(obj) {
    return this.apiService.apiPost('/action', obj);
  }

  putAction(id, obj) {
    return this.apiService.apiPut('/action/' + id, obj);
  }

  deleteAction(id) {
    return this.apiService.apiDelete('/action', id);
  }

  getActionById(id) {
    return this.apiService.apiGet('/action/' + id);
  }

  getActionByCategory(idCategory) {
    return this.apiService.apiGet('/action/category/' + idCategory);
  }

  postActionMaterial(obj) {
    return this.apiService.apiPost('/action-material', obj);
  }

  putActionMaterial(id, obj) {
    return this.apiService.apiPut('/action-material/' + id, obj);
  }

}
