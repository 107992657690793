import { Injectable } from '@angular/core';

import { ApiService } from './../http/api.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnnotationDesignService {

  constructor(private apiService: ApiService) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getAnnotationDesignByFranchise(id) {
    return this.apiService.apiGet(`/annotation-design-franchise/filter-by-franchise/${id}`);
  }

  getAnnotationDesign() {
    return this.apiService.apiGet(`/annotation-design-franchise`);
  }

  postAnnotationDesign(obj) {
    return this.apiService.apiPost('/annotation-design-franchise', obj);
  }

  putAnnotationDesign(id, obj) {
    return this.apiService.apiPut(`/annotation-design-franchise/${id}`, obj);
  }

}
