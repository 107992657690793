import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { DateService } from 'src/app/shared/services/date.service';
import { FranchisesService } from 'src/app/core/services/franchises.service';
import { MarketingPlanService } from 'src/app/core/services/marketing-plan.service';
import Swal from 'sweetalert2';
import { HalfYear } from 'src/app/core/model/half';
import { HalfService } from 'src/app/core/services/half.service';

@Component({
  selector: 'app-modal-clone-plan',
  templateUrl: './modal-clone-plan.component.html',
  styleUrls: ['./modal-clone-plan.component.css']
})
export class ModalClonePlanComponent implements OnInit {
  years: number[];
  franchises: any[];
  cloneForm: FormGroup;
  clicked = false;
  logging = false;
  public halfYears:HalfYear[] = [];
  @Input() plan: any;

  constructor(
    public modal: NgbActiveModal,
    private router: Router,
    private dateService: DateService,
    private franchisesService: FranchisesService,
    private marketingPlanService: MarketingPlanService,
    private halfService: HalfService
  ) { }

  async ngOnInit() {
    this.cloneForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      franchise: new FormControl(null, [Validators.required]),
      half: new FormControl(null, [Validators.required])
    });
    await this.getFranchises();
    await this.getHalfs();

    if (this.plan) {
      this.cloneForm.controls['name'].setValue(this.plan.name);

      let halfSelected = this.halfYears.filter(h => h.id == this.plan.period_id)[0];

      this.cloneForm.controls['half'].setValue(halfSelected);
      this.cloneForm.controls['half'].updateValueAndValidity();
      this.cloneForm.updateValueAndValidity();
    }

  }

  get f() { return this.cloneForm.controls; }

  async getFranchises() {
    this.franchises = await this.franchisesService.getFranchises();
  }

  async getHalfs(){
    this.halfYears = await this.halfService.listHalfAndYearSelect();
  }

  async applyClonePlan() {
    this.clicked = true;
    this.logging = true;
    if (this.cloneForm.invalid) {
      this.clicked = false;
      this.logging = false;
      return;
    }
    const objNewPlan = {
      name: this.f.name.value,
      franchise: this.f.franchise.value,
      period_id: this.f.half.value.id,
    };

    const resp = await this.marketingPlanService.postClonePlan(this.plan.id, objNewPlan);
    if(resp){
      this.router.navigate(['/marketing-plans/' + resp.id]);
      Swal.fire({
        icon: 'success',
        title: 'Seu trabalho foi salvo',
        showConfirmButton: false,
        timer: 1500
      })
      this.close();
    } else{
      Swal.fire({
        title: 'Error!',
        icon: 'error',
        confirmButtonText: 'Cool'
      });
      this.close();

    }
  }

  selectFranchise(franchise: any) {
    this.cloneForm.patchValue({franchise: franchise.id});
  }

  close() {
    const shadesEl = document.querySelector('.modal.fade');
    shadesEl.classList.add('zoomOut');
    setTimeout(() => {
      this.modal.close();
    }, 300);
  }
}
