import { Injectable } from '@angular/core';

import { ApiService } from './../http/api.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DescriptionService {

  constructor(private apiService: ApiService) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getDescription() {
    return this.apiService.apiGet('/description');
  }

  postDescription(obj) {
    return this.apiService.apiPost('/description', obj);
  }

  putDescription(id, obj) {
    return this.apiService.apiPut('/description/' + id, obj);
  }

  deleteDescription(id) {
    return this.apiService.apiDelete('/description', id);
  }

  getDescriptionById(id) {
    return this.apiService.apiGet('/description/' + id);
  }

  getDescriptionByAction(idAction) {
    return this.apiService.apiGet('/description/action/' + idAction);
  }

}
