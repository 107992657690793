import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { SharedModule } from '../shared/shared.module';

import { ApiService } from './http/api.service';
import { AuthService } from './services/auth.service';
import { ActionService } from './services/action.service';
import { MaterialService } from './services/material.service';
import { FinancialService } from './services/financial.service';
import { FranchisesService } from './services/franchises.service';
import { DescriptionService } from './services/description.service';
import { MarketingPlanService } from './services/marketing-plan.service';
import { PopulationGroupService } from './services/population-group.service';
import { AcquisitionGroupService } from './services/acquisition-group.service';
import { AnnotationDesignService } from './services/annotation-design.service';

import { AuthGuard } from './guards/auth.guard';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { RoleGuard } from './guards/role.guard';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    SharedModule
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    ApiService,
    AcquisitionGroupService,
    ActionService,
    AuthService,
    DescriptionService,
    FinancialService,
    FranchisesService,
    MarketingPlanService,
    MaterialService,
    PopulationGroupService,
    AnnotationDesignService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class CoreModule { }
