import { Injectable } from '@angular/core';

import { ApiService } from './../http/api.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(private apiService: ApiService) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getMaterial() {
    return this.apiService.apiGet('/material');
  }

  postMaterial(obj) {
    return this.apiService.apiPost('/material', obj);
  }

  putMaterial(id, obj) {
    return this.apiService.apiPut('/material/' + id, obj);
  }

  deleteMaterial(id) {
    return this.apiService.apiDelete('/material', id);
  }

  getMaterialById(id) {
    return this.apiService.apiGet('/material/' + id);
  }

  getMaterialByCategory(idCategory) {
    return this.apiService.apiGet('/material/action/' + idCategory + '/marketing-plan/');
  }

}
