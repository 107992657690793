import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '', children: [
    {
      path: 'dashboard',
      loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    { path: 'marketing-plans',
      loadChildren: () => import('./modules/marketing-plans/marketing-plans.module').then(m => m.MarketingPlansModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'report',
      loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'franchises',
      loadChildren: () => import('./modules/franchises/franchises.module').then(m => m.FranchisesModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'actions',
      loadChildren: () => import('./modules/action/action.module').then(m => m.ActionModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'descriptions',
      loadChildren: () => import('./modules/description/description.module').then(m => m.DescriptionModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'materials',
      loadChildren: () => import('./modules/material/material.module').then(m => m.MaterialModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'population-group',
      loadChildren: () => import('./modules/population-group/population-group.module').then(m => m.PopulationGroupModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'plan-models',
      loadChildren: () => import('./modules/plan-model/plan-model.module').then(m => m.PlanModelModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'acquisition-group',
      loadChildren: () => import('./modules/acquisition-group/acquisition-group.module').then(m => m.AcquisitionGroupModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'half',
      loadChildren: () => import('./modules/half/half.module').then(m => m.HalfModule),
      canActivate: [AuthGuard, RoleGuard]
    },
    {
      path: 'plans',
      loadChildren: () => import('./modules/plan/plan.module').then(m => m.PlanModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'annotation-design',
      loadChildren: () => import('./modules/annotation-design/annotation-design.module').then(m => m.AnnotationDesignModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'login',
      loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    }
  ] },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
