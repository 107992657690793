import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class ApiService {
  public token: string;
  public headerContentType: string;
  public headerAccept: string;
  public HEADERS: any;
  public BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = environment.BACKEND_URL;
    this.HEADERS = new HttpHeaders({
      'Content-Type'  : 'application/json',
      'Accept' : '*/*',
      'Accept-Encoding': 'gzip, deflate, br'
    });
  }

  setHeaders() {
    // TODO implementar novo HEADER
  }

  setAPI(url) {
    this.BACKEND_URL = url;
    return this;
  }

  async apiGet(url: string, options?: string) {
    if (options) { return await this.http.get(this.BACKEND_URL + url + '?' + options + '', {headers: this.HEADERS}).toPromise(); }
    return await this.http.get<any>(this.BACKEND_URL + url + '', {headers: this.HEADERS}).toPromise();
  }

  async apiPost(url: string, object) {
    return await this.http.post<any>(this.BACKEND_URL + url + '', object, {headers: this.HEADERS}).toPromise();
  }

  async apiPut(url: string, object) {
    return await this.http.put<any>(this.BACKEND_URL + url + '', object, {headers: this.HEADERS}).toPromise();
  }

  async apiDelete(url, id) {
    return await this.http.delete(this.BACKEND_URL + url + '/' + id, {headers: this.HEADERS}).toPromise();
  }

  login(url: string, object) {
    return this.http.post<any>(this.BACKEND_URL + url + '', object, {headers: this.HEADERS});
  }
}
