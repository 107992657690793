import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './../../core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  franchiseName: string;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }


  ngOnInit() {
    this.getFranchiseName();
  }

  getFranchiseName() {
    this.franchiseName = this.authService.getFranchiseName();
  }

  logout() {
    this.authService.logout();
  }

}
