import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { DateService } from 'src/app/shared/services/date.service';
import { FranchisesService } from 'src/app/core/services/franchises.service';
import { MarketingPlanService } from 'src/app/core/services/marketing-plan.service';
import { HalfService } from 'src/app/core/services/half.service';
import { HalfYear } from 'src/app/core/model/half';

@Component({
  selector: 'app-modal-new-plan',
  templateUrl: './modal-new-plan.component.html',
  styleUrls: ['./modal-new-plan.component.css']
})
export class ModalNewPlanComponent implements OnInit {
  franchises: any[];
  planForm: FormGroup;
  public halfYears:HalfYear[] = [];

  @Input() plan: any;
  @Output() emitPutPlan = new EventEmitter<any>();

  constructor(
    public modal: NgbActiveModal,
    private router: Router,
    private dateService: DateService,
    private franchisesService: FranchisesService,
    private marketingPlanService: MarketingPlanService,
    private halfService: HalfService,
  ) { }

  async ngOnInit() {
    this.planForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      franchise: new FormControl(null, [Validators.required]),
      half: new FormControl(null, [Validators.required])
    });

    this.getHalfs()

    await this.getFranchises();
    if (this.plan) {
      this.planForm.controls['name'].setValue(this.plan.name);

      let halfSelected = this.halfYears.filter(h => h.id == this.plan.period_id)[0];
      this.planForm.controls['half'].setValue(halfSelected);
      this.planForm.controls['half'].updateValueAndValidity();
      this.planForm.updateValueAndValidity();
    }
  }

  get f() { return this.planForm.controls; }

  async getFranchises() {
    this.franchises = await this.franchisesService.getFranchises();
  }


  async getHalfs(){
    this.halfYears = await this.halfService.listHalfAndYearSelect();
  }

  async applyNewPlan() {
    if (this.planForm.invalid) { return; }

    const objNewPlan = {
      name: this.f.name.value,
      franchise: this.f.franchise.value,
      period_id: this.f.half.value.id,
    };
    const resp = this.plan ?
      await this.marketingPlanService.putPlan(this.plan.id, objNewPlan) :
      await this.marketingPlanService.postNewPlan(objNewPlan);

    this.plan ?
      this.emitPutPlan.emit(resp) :
      this.router.navigate([`/marketing-plans/${resp.id}`]);

    this.close();
  }

  selectFranchise(franchise: any) {
    this.planForm.patchValue({franchise: franchise.id});
  }

  close() {
    const shadesEl = document.querySelector('.modal.fade');
    shadesEl.classList.add('zoomOut');
    setTimeout(() => {
      this.modal.close();
    }, 300);
  }
}
