import { Injectable } from '@angular/core';

import { ApiService } from './../http/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AcquisitionGroupService {

  constructor(private apiService: ApiService) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getAcquisitionGroup(options: string = '') {
    return this.apiService.apiGet(`/acquisition-group${options}`);
  }

  postAcquisitionGroup(obj) {
    return this.apiService.apiPost('/acquisition-group', obj);
  }

  putAcquisitionGroup(id, obj) {
    return this.apiService.apiPut('/acquisition-group/' + id, obj);
  }

  deleteAcquisitionGroup(id) {
    return this.apiService.apiDelete('/acquisition-group', id);
  }

  getAcquisitionGroupById(id) {
    return this.apiService.apiGet('/acquisition-group/' + id);
  }

  postFranchiseInAcquisitionGroup(obj) {
    return this.apiService.apiPost('/acquisition-group-franchise', obj);
  }

  putFranchiseInAcquisitionGroup(id, obj) {
    return this.apiService.apiPut('/acquisition-group-franchise/' + id, obj);
  }

  postMultipleFranchiseInAcquisitionGroup(id, obj) {
    return this.apiService.apiPost('/acquisition-group-franchise/multiple/' + id, obj);
  }

  postItemInAcquisitionGroup(obj) {
    return this.apiService.apiPost('/marketing-plan-item', obj);
  }

  putItemInAcquisitionGroup(id, obj) {
    return this.apiService.apiPut('/marketing-plan-item/' + id, obj);
  }

  putItemDetailInAcquisitionGroup(id, obj) {
    return this.apiService.apiPut('/marketing-plan-item-detail/' + id, obj);
  }

}
