import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';
import { ApiService } from './../http/api.service';

@Injectable({
  providedIn: 'root'
})
export class MarketingPlanService {

  constructor(
    private apiService: ApiService
  ) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getMarketingPlan(options: string = '') {
    return this.apiService.apiGet(`/marketing-plan${options}`);
  }

  updateMarketingPlan(id: string) {
    return this.apiService.apiGet(`/marketing-plan/update-values-from-financial/${id}`);
  }

  getMarketingPlanByIdPlan(idPlan) {
    return this.apiService.apiGet('/marketing-plan/' + idPlan);
  }

  getMarketingPlanByFranchiseFinancialId(idFranchiseFinancial: string, options: string = '') {
    return this.apiService.apiGet(`/marketing-plan/financial-identify-franchise/${idFranchiseFinancial}${options}`);
  }

  getMarketingPlanByFranchiseFinancialIdOrders(idFranchiseFinancial: string) {
    return this.apiService.apiGet(`/marketing-plan/financial-identify-franchise/orders/${idFranchiseFinancial}`);
  }

  getOriginalMarketingPlan(idPlan) {
    return this.apiService.apiGet('/marketing-plan-version/' + idPlan);
  }

  postNewPlan(obj) {
    return this.apiService.apiPost('/marketing-plan', obj);
  }

  putPlan(id, obj) {
    return this.apiService.apiPut('/marketing-plan/' + id, obj);
  }

  deletePlan(id) {
    return this.apiService.apiDelete('/marketing-plan', id);
  }

  postItemPlan(obj) {
    return this.apiService.apiPost('/marketing-plan-item', obj);
  }

  deleteItemPlan(idPlanItem) {
    return this.apiService.apiDelete('/marketing-plan-item', idPlanItem);
  }

  putItemDetailPlan(idPlanItem, obj) {
    return this.apiService.apiPut('/marketing-plan-item-detail/' + idPlanItem, obj);
  }

  putItemPlan(idPlanItem, obj) {
    return this.apiService.apiPut('/marketing-plan-item/' + idPlanItem, obj);
  }

  getCategory() {
    return this.apiService.apiGet('/category');
  }

  getPlanModels(options: string = '') {
    return this.apiService.apiGet(`/marketing-plan/models/all${options}`);
  }

  postClonePlan(id, obj) {
    return this.apiService.apiPost('/marketing-plan/clone/plan/' + id, obj);
  }

  putItemRemovable(id, obj) {
    return this.apiService.apiPut(`/marketing-plan-item/apply-removable/${id}`, obj);
  }

  putItemRequestRemoval(id, obj) {
    return this.apiService.apiPut(`/marketing-plan-item/apply-justification/${id}`, obj);
  }

  getScorePlansStatus(options: string = '') {
    return this.apiService.apiGet(`/marketing-plan/score-plans-status${options}`);
  }
}
