import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DateService } from './services/date.service';
import { AlertService } from './services/alert.service';
import { TablesService } from './services/tables.service';

import { FormErrorComponent } from './components/form-error/form-error.component';
import { ModalNewPlanComponent } from './modals/modal-new-plan/modal-new-plan.component';
import { ModalClonePlanComponent } from './modals/modal-clone-plan/modal-clone-plan.component';

import { CallbackPipe } from './pipe/filter.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule, } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MsSelectSearchComponent } from './components/ms-select-search/ms-select-search.component';
import { CardFloatingComponent } from './components/card-floating/card-floating.component';
import { FilterComponent } from './components/filter/filter.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { UtilService } from './services/util.service';
import { MaskDirective } from './directives/mask.directive';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
    declarations: [
      FormErrorComponent,
      ModalClonePlanComponent,
      ModalNewPlanComponent,
      MsSelectSearchComponent,
      CardFloatingComponent,
      FilterComponent,
      CallbackPipe,
      MaskDirective
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      NgxMaskModule.forRoot(),
      NgxCurrencyModule,
      NgxMatSelectSearchModule,
      MatFormFieldModule,
      MatSelectModule,
      MatProgressBarModule,
      MatButtonModule,
      MatIconModule,
      MatInputModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatSelectInfiniteScrollModule,
      MatAutocompleteModule,
      GoogleChartsModule.forRoot(),
      MatRadioModule,
      MatNativeDateModule
    ],
    exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      NgxMaskModule,
      NgxCurrencyModule,
      MatFormFieldModule,
      MatAutocompleteModule,
      MatSelectModule,
      MatProgressBarModule,
      MatInputModule,
      MatCheckboxModule,
      MatRadioModule,
      FormErrorComponent,
      ModalClonePlanComponent,
      ModalNewPlanComponent,
      MsSelectSearchComponent,
      CardFloatingComponent,
      FilterComponent,
      CallbackPipe,
      GoogleChartsModule,
      MaskDirective,
      MatDatepickerModule,
      MatNativeDateModule,
    ],
    providers: [
      DateService,
      AlertService,
      TablesService,
      UtilService
    ],
    entryComponents: [
      ModalClonePlanComponent,
      ModalNewPlanComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
