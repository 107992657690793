import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { Half, HalfYear } from '../model/half';

@Injectable({
  providedIn: 'root'
})
export class HalfService {

  constructor(private apiService: ApiService) {

  }

  postHalf(obj:Half):Promise<Half> {
    return this.apiService.apiPost('/period', obj)
  }

  listHalfs():Promise<Half[]>{
    return this.apiService.apiGet('/period');
  }

  getByIdHalf(id: string):Promise<Half> {
    return this.apiService.apiGet('/period/'+id);
  }

  putHalf(obj:Half):Promise<Half> {
    return this.apiService.apiPut('/period/'+obj.id, obj);
  }

  deleteHalf(id:string):Promise<any> {
    return this.apiService.apiDelete('/period', id);
  }

  listHalfAndYearSelect():Promise<HalfYear[]>{
    return this.apiService.apiGet('/period/select');
  }

}
