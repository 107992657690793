import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'influx-marketing';

  userAuthenticaded = false;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.userAuthenticatedEmitter.subscribe(arg => this.userAuthenticaded = arg);
  }

}
