import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-ms-select-search',
  templateUrl: './ms-select-search.component.html',
  styleUrls: ['./ms-select-search.component.css']
})
export class MsSelectSearchComponent implements OnInit {
  myCtrl = new FormControl();
  filteredOptions: Observable<any[]>;

  @Input() options: any[];
  @Input() getAll: boolean;
  @Input() placeholder: string;
  @Input() addClass: string|null;
  @Output() optionSelected = new EventEmitter<any>();
  @ViewChild('matInput') matInput: ElementRef;

  constructor() { }

  ngOnInit() {
    this.filteredOptions = this.myCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice())
      );
  }

  displayFn(user?: any): string | undefined {
    return user ? user.name : undefined;
  }

  clearString(stringEl) {
    return stringEl.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(
      option => this.clearString(option.name)
                .toLowerCase()
                .indexOf(filterValue) !== -1
    );
  }

  setOptions() {
    this.optionSelected.emit(this.myCtrl.value);
    this.matInput.nativeElement.blur();
  }

}
