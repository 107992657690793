import { Injectable } from '@angular/core';

import { ApiService } from './../http/api.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PopulationGroupService {

  constructor(private apiService: ApiService) {
    this.apiService.setAPI(environment.BACKEND_URL);
  }

  getPopulationGroup() {
    return this.apiService.apiGet('/population-numbers-group');
  }

  postPopulationGroup(obj) {
    return this.apiService.apiPost('/population-numbers-group', obj);
  }

  putPopulationGroup(id, obj) {
    return this.apiService.apiPut('/population-numbers-group/' + id, obj);
  }

  deletePopulationGroup(id) {
    return this.apiService.apiDelete('/population-numbers-group', id);
  }

  getPopulationGroupById(id) {
    return this.apiService.apiGet('/population-numbers-group/' + id);
  }

}
