import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService.isAuthenticated();
    const role = this.authService.appUserRoleState();

    if (!user) {
      return false;
    }

    if (!role) {
      this.router.navigate(['/plans']);
      return false;
    }

    return true;
  }

}
