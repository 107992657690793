export const head: any[] = [
  {
    referenceCode: "FIRST_HALF",
    data: [
      { name: "Janeiro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Fevereiro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Março", value: ["1ª Qui", "2ª Qui"] },
      { name: "Abril", value: ["1ª Qui", "2ª Qui"] },
      { name: "Maio", value: ["1ª Qui", "2ª Qui"] },
      { name: "Junho", value: ["1ª Qui", "2ª Qui"] },
      { name: "VL\nUnit", value: [], tooltip: "VALOR REFERENTE A PRODUTOS (NÃO EDITÁVEL)" },
      { name: "Qtd", value: [] },
      { name: "VL\nTotal ", value: [], tooltip: "VALOR FINAL (NÃO EDITÁVEL)" },
    ],
  },
  {
    referenceCode: "SECOND_HALF",
    data: [
      { name: "Julho", value: ["1ª Qui", "2ª Qui"] },
      { name: "Agosto", value: ["1ª Qui", "2ª Qui"] },
      { name: "Setembro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Outubro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Novembro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Dezembro", value: ["1ª Qui", "2ª Qui"] },
      { name: "VL\nUnit", value: [] },
      { name: "Qtd", value: [] },
      { name: "VL\nTotal ", value: [] },
    ],
  },
];

export const headAcquisition: any[] = [
  {
    referenceCode: "FIRST_HALF",
    data: [
      { name: "Janeiro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Fevereiro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Março", value: ["1ª Qui", "2ª Qui"] },
      { name: "Abril", value: ["1ª Qui", "2ª Qui"] },
      { name: "Maio", value: ["1ª Qui", "2ª Qui"] },
      { name: "Junho", value: ["1ª Qui", "2ª Qui"] },
      { name: "Qtd", value: [] },
    ],
  },
  {
    referenceCode: "SECOND_HALF",
    data: [
      { name: "Julho", value: ["1ª Qui", "2ª Qui"] },
      { name: "Agosto", value: ["1ª Qui", "2ª Qui"] },
      { name: "Setembro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Outubro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Novembro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Dezembro", value: ["1ª Qui", "2ª Qui"] },
      { name: "Qtd", value: [] },
    ],
  },
];

export const franchise: any[] = [
  { name: "Escola A", referenceCode: "a" },
  { name: "Escola B", referenceCode: "b" },
  { name: "Escola C", referenceCode: "c" },
];
